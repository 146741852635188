.footer {
  padding-top: 57.8px;
  padding-bottom: 84.3px;
  background-color: #f7f7f7;
}

.footer__cont {
  width: 789.256px;
  margin: 0 auto;
}

.footer__nav {
  justify-content: space-between;

  li {
    a {
      font-size: 18.0789px;
      font-weight: 700;
      color: #808080;
    }
  }
}

.footer__flex {
  margin-top: 25.297px;
  padding-left: 21.051px;
  padding-top: 14.4342px;
  padding-bottom: 12.0261px;
  padding-right: 14.4342px;
  border-radius: 10px;
  background-color: #eaeaea;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  img {
    width: 211.7636px;
    min-width: 211.7636px;
    height: auto;
  }

  p {
    width: calc(100% - 226.2364px);
    font-size: 11.4287px;
    font-weight: 400;
    color: #b0b0b0;
    text-align: justify;
  }
}

.footer__disc {
  margin-top: 22.867px;
  text-align: justify;
  font-size: 11.4379px;
  color: #b0b0b0;
  font-weight: 400;
}


@media screen and (max-width: 1100px) {
  .footer {
    padding-top: 4.81vw;
    padding-bottom: 7.02vw;
  }
  .footer__cont {
    width: 65.79vw;
  }
  .footer__nav {
    li {
      a {
        font-size: 1.5vw;
      }
    }
  }
  .footer__flex {
    margin-top: 2.1vw;
    padding-left: 1.75vw;
    padding-top: 1.2vw;
    padding-bottom: 1vw;
    padding-right: 1.2vw;
    border-radius: 0.5vw;
    img {
      width: 17.65vw;
      min-width: 17.65vw;
    }
    p {
      width: calc(100% - 18.85vw);
      font-size: 0.95vw;
    }
  }
  .footer__disc {
    margin-top: 1.9vw;
    font-size: 0.95vw;
  }
}


@media screen and (max-width: 778px) {
  .footer {
    padding-top: 9.26vw;
    padding-bottom: 9.26vw;
  }
  .footer__cont {
    width: 89.01vw;
  }
  .footer__nav {
    li {
      margin: 0 2.57vw;
      margin-bottom: 2.57vw;
      a {
        font-size: 3.86vw;
      }
    }
  }
  .footer__flex {
    margin-top: 0.77vw;
    padding-left: 3.34vw;
    padding-top: 5.92vw;
    padding-bottom: 2.57vw;
    padding-right: 3.34vw;
    border-radius: 1.93vw;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    img {
      width: 47.55vw;
      min-width: 47.55vw;
    }
    p {
      width: 100%;
      margin-top: 4.12vw;
      font-size: 2.44vw;
    }
  }
  .footer__disc {
    margin-top: 4.12vw;
    font-size: 2.56vw;
  }
}