.section__recom {
  padding-top: 32.00px;
  padding-bottom: 22.90px;
  background-color: #006ecf;
}

.recom__content {
  width: 806.07px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.recom__title {
  font-size: 45.14px;
  letter-spacing: 0.01em;
  color: #fff;
  font-weight: 700;

  span {
    color: $yellow;
  }
}

.recpm__text {
  font-size: 23.89px;
  margin-top: 4.81px;
  font-weight: 400;
  color: #fff;

  a {
    color: #2ef4ff;
  }
}

.recom__right {
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;

  img {
    width: 153.71px;
    height: auto;
  }
}

.recom__timer {
  font-size: 49.99px;
  margin-top: 12.52px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;
}

.qual__btn {
  width: 686.93px;
  height: 88.87px;
  line-height: 88.87px;
  border-radius: 88.87px;
  margin: 0 auto;
  margin-top: 34.89px;
  font-size: 39.98px;
  -webkit-box-shadow: inset 0px -7.70px 0px -2.03px rgba(0,0,0,0.08);
  -moz-box-shadow: inset 0px -7.70px 0px -2.03px rgba(0,0,0,0.08);
  box-shadow: inset 0px -7.70px 0px -2.03px rgba(0,0,0,0.08);
  color: $blul;
  text-align: center;
  background: linear-gradient(360deg, rgba(255,179,1,1) 0%, rgba(255,222,0,1) 100%);

  * {
    vertical-align: middle;
  }
  span {
    font-size: 60.77px;
  }
}


@media screen and (max-width: 1100px) {
  .section__recom {
    padding-top: 3.21vw;
    padding-bottom: 2.31vw;
  }
  .recom__content {
    width: 80.96vw;
  }
  .recom__title {
    font-size: 4.51vw;
  }
  .recpm__text {
    font-size: 2.41vw;
    margin-top: 0.50vw;
  }
  .recom__right {
    img {
      width: 11.44vw;
    }
  }
  .recom__timer {
    font-size: 5.01vw;
    margin-top: 1.25vw;
  }
  .qual__btn {
    width: 69.14vw;
    height: 8.92vw;
    line-height: 8.92vw;
    border-radius: 8.92vw;
    margin-top: 3.51vw;
    font-size: 4.01vw;
    -webkit-box-shadow: inset 0px -0.75vw 0px -0.2vw rgba(0,0,0,0.08);
    -moz-box-shadow: inset 0px -0.75vw 0px -0.2vw rgba(0,0,0,0.08);
    box-shadow: inset 0px -0.75vw 0px -0.2vw rgba(0,0,0,0.08);
    span {
      font-size: 6.02vw;
    }
  }
}


@media screen and (max-width: 778px) {
  .section__recom {
    padding: 9.79vw 0;
  }
  .recom__content {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
  .recom__left {
    margin-top: 6.43vw;
    text-align: center;
    order: 2;
  }
  .recom__title {
    font-size: 7.98vw;
    letter-spacing: 0.025em;
    br {
      display: none;
    }
  }
  .recpm__text {
    font-size: 7.07vw;
    margin-top: 5.14vw;
  }
  .recom__right {
    padding-right: 3.86vw;
    img {
      width: 25.99vw;
    }
  }
  .recom__timer {
    display: none;
  }
  .qual__btn {
    display: none;
  }
}