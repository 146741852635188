.section__first {
  padding-top: 34.65px;
  padding-bottom: 43.26px;
  background-color: $blul;
}

.first__title {
  font-size: 45.91px;
  color: $yellow;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.0125em;

  span {
    font-size: 41.31px;
  }
}

.first__video {
  width: 798.52px;
  margin: 0 auto;
  margin-top: 34.65px;
  border: 5.98px solid #fff;
  border-radius: 16.29px;
  overflow: hidden;
}

.first__videoinner {
  padding-top: calc(100% / 16 * 9);
  position: relative;
  display: block;
}


@media screen and (max-width: 1100px) {
  .section__first {
    padding-top: 3.21vw;
    padding-bottom: 4.01vw;
  }
  .first__title {
    font-size: 4.26vw;
  }
  .first__video {
    width: 74.3vw;
    margin-top: 3.21vw;
    border: 0.55vw solid #fff;
    border-radius: 1.5vw;
  }
}


@media screen and (max-width: 778px) {
  .section__first {
    padding: 5.1469vw 0;
  }
  .first__title {
    font-size: 6.1712vw;
    letter-spacing: -0.025em;
    br {
      display: none;
    }
  }
  .first__video {
    width: 94.6398vw;
    margin-top: 5.1469vw;
    border: 1.5414vw solid #fff;
    border-radius: 2.5715vw;
  }
  .first__videoinner {
    padding-top: 127.78%;
  }
}