.section__qual {
  padding-top: 39.697px;
  padding-bottom: 85.830px;
  background-color: #f2f2f2;
}

.qual__stars {
  justify-content: center;

  span {
    margin-right: 7.259px;

    &:last-child {
      margin: 0;
    }

    img {
      width: 37.99px;
      height: auto;
      display: block;
    }
  }
}

.qual__title {
  font-size: 70.052px;
  margin-top: 10.989px;
  color: $blul;
  text-align: center;
  font-weight: 700;
}

.qual__text {
  font-size: 19.963px;
  max-width: 736.835px;
  margin: 0 auto;
  margin-top: 24.959px;
  font-weight: 400;
  text-align: center;
}

.qual__images {
  margin-top: 36.818px;
  justify-content: center;

  li {
    margin-right: 12.485px;

    &:last-child {
      margin: 0;
    }

    img {
      height: 86.41px;
      width: auto;
      display: block;
    }
  }
}

.qual__icons {
  margin-top: 36.570px;
  justify-content: center;

  li {
    margin-right: 12.225px;

    &:last-child {
      margin: 0;
    }

    img {
      width: 97.27px;
      height: auto;
      display: block;
    }
  }
}


@media screen and (max-width: 1100px) {
  .section__qual {
    padding-top: 4.01vw;
    padding-bottom: 8.63vw;
  }
  .qual__stars {
    span {
      margin-right: 0.7vw;
      img {
        width: 3.81vw;
      }
    }
  }
  .qual__title {
    font-size: 7.01vw;
    margin-top: 1.1vw;
  }
  .qual__text {
    font-size: 2.01vw;
    max-width: 73.92vw;
    margin-top: 2.51vw;
  }
  .qual__images {
    margin-top: 3.71vw;
    li {
      margin-right: 1.25vw;
      img {
        height: 8.68vw;
      }
    }
  }
  .qual__icons {
    margin-top: 3.66vw;
    li {
      margin-right: 1.2vw;
      img {
        width: 9.78vw;
      }
    }
  }
}


@media screen and (max-width: 778px) {
  .section__qual {
    padding-top: 8.99vw;
    padding-bottom: 13.88vw;
    .container {
      display: flex;
      flex-direction: column;
    }
  }
  .qual__top {
    width: 83.97vw;
    padding: 3.86vw;
    padding-bottom: 2.57vw;
    border-radius: 2.58vw;
    margin: 0 auto;
    background-color: $blul;
  }
  .qual__stars {
    span {
      margin-right: 1.54vw;
      img {
        width: 6.94vw;
      }
    }
  }
  .qual__title {
    font-size: 8.99vw;
    margin-top: 3.86vw;
    color: #fff;
  }
  .qual__text {
    display: none;
  }
  .qual__images {
    margin-top: 6.42vw;
    margin-bottom: -4.89vw;
    order: 1;
    li {
      margin: 0 0.9vw !important;
      margin-bottom: 4.89vw !important;
      &:first-child {
        order: 1;
        img {
          height: 21.07vw;
        }
      }
      img {
        height: 18.22vw;
      }
    }
  }
  .qual__icons {
    margin-top: 6.42vw;
    margin-bottom: -2.57vw;
    li {
      margin: 0 1.28vw !important;
      margin-bottom: 2.57vw !important;
      &:last-child {
        display: none;
      }
      img {
        width: 23.93vw;
      }
    }
  }
}