.section__bonuses {
  padding-top: 30.95px;
  padding-bottom: 65.27px;
  background-color: $blul;
}

.bonuses__title {
  font-size: 60.35px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  letter-spacing: 0.01em;

  span {
    text-decoration-color: $yellow !important;
    text-decoration-thickness: 3.5px !important;
    text-underline-position: under !important;
  }
}

.bonuses__list {
  margin-top: 44.94px;
  justify-content: center;
}

.bonus__item {
  width: 387.14px;
  margin: 0 14.02px;
  letter-spacing: 0.01em;
}

.bonus__main {
  border-radius: 15px;
  padding: 7.01px;
  background-color: #fff;
}

.bonus__img {
  border-radius: 9.99px;
  padding-top: 47.16%;
  position: relative;
  overflow: hidden;
}

.bonuses__maininfo {
  padding-top: 34.93px;
  padding-bottom: 40.74px;
  padding-left: 29.92px;
  padding-right: 29.92px;
  text-align: center;
}

.bonus__num {
  font-size: 29.92px;
  color: $red;
}

.bonus__name {
  font-size: 29.92px;
  color: $blul;
  font-weight: 700;
  letter-spacing: -0.015em;
}

.bonus__bottom {
  padding-top: 15.93px;
}

.bonus__price {
  font-size: 28.01px;
  margin-top: 11.96px;
  font-weight: 400;
  letter-spacing: -0.015em;
  text-align: center;
  color: $blul;

  span {
    position: relative;
    display: inline-block;
    &::after {
      height: 5.1px;
      border-radius: 5.1px;
      background-color: $red;
      display: block;
      content: '';
      left: -0.09em;
      right: -0.09em;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }

  b {
    font-weight: 700;
    color: $green;
    text-transform: uppercase;
  }
}

.bonys__desc {
  color: $blul;
  font-size: 19.94px;
  margin-top: 22.44px;
  font-weight: 400;
  text-align: justify;
}


@media screen and (max-width: 1100px) {
  .section__bonuses {
    padding-top: 3.11vw;
    padding-bottom: 6.52vw;
  }
  .bonuses__title {
    font-size: 6.02vw;
    span {
      text-decoration-thickness: 0.35vw !important;
    }
  }
  .bonuses__list {
    margin-top: 4.51vw;
  }
  .bonus__item {
    width: 38.76vw;
    margin: 1.40vw;
    letter-spacing: 0.01em;
  }
  .bonus__main {
    border-radius: 1.50vw;
    padding: 0.70vw;
  }
  .bonus__img {
    border-radius: 1.00vw;
  }
  .bonuses__maininfo {
    padding-top: 3.51vw;
    padding-bottom: 4.01vw;
    padding-left: 3.01vw;
    padding-right: 3.01vw;
  }
  .bonus__num {
    font-size: 3.01vw;
  }
  .bonus__name {
    font-size: 3.01vw;
  }
  .bonus__bottom {
    padding-top: 1.60vw;
  }
  .bonus__price {
    font-size: 2.76vw;
    margin-top: 1.2vw;
    span {
      &::after {
        height: 0.50vw;
        border-radius: 0.50vw;
      }
    }
  }
  .bonys__desc {
    font-size: 2.01vw;
    margin-top: 2.26vw;
  }
}


@media screen and (max-width: 778px) {
  .section__bonuses {
    padding-top: 8.98vw;
    padding-bottom: 8.98vw;
  }
  .bonuses__title {
    font-size: 9.63vw;
    margin: 7.72vw 7.72vw;
    font-weight: 700;
    color: #fff;
    text-align: center;
    letter-spacing: 0.01em;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: -2.05vw;
      height: 1.54vw;
      left: 0;
      right: 0;
      background-color: $yellow;
      border-radius: 3.08vw;
    }
    br {
      display: none;
    }
    span {
      text-decoration: none !important;
    }
  }
  .bonuses__list {
    margin-top: 15.15vw;
    justify-content: center;
  }
  .bonus__item {
    width: 93.65vw;
    margin: 0;
    margin-bottom: 6.94vw;
    &:last-child {
      margin: 0;
    }
  }
  .bonus__main {
    border-radius: 2.57vw;
    padding: 1.54vw;
  }
  .bonus__img {
    border-radius: 2.06vw 2.06vw 0 0;
    padding-top: 90%;
  }
  .bonuses__maininfo {
    padding-top: 9.63vw;
    padding-bottom: 10.28vw;
    padding-left: 7.19vw;
    padding-right: 7.19vw;
  }
  .bonus__num {
    font-size: 7.33vw;
  }
  .bonus__name {
    font-size: 7.33vw;
  }
  .bonus__bottom {
    padding-top: 3.08vw;
  }
  .bonus__price {
    font-size: 6.43vw;
    margin-top: 0.77vw;
    span {
      &::after {
        height: 0.82vw;
        border-radius: 0.82vw;
      }
    }
  }
  .bonys__desc {
    font-size: 4.1vw;
    margin-top: 3.08vw;
    line-height: 1.2;
    font-weight: 300;
    color: #004a99;
  }
}