.section__faq {
  padding-top: 44.89px;
  padding-bottom: 63.92px;
  background-color: $blul;
}

.faq__title {
  font-size: 60.34px;
  color: #fff;
  text-align: center;
  font-style: italic;
}

.faq__cont {
  margin: 0 auto;
  margin-top: 26.93px;
  max-width: 668.67px;
}

.faq__item {
  margin-bottom: 7.97px;
  border-radius: 10px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.faq__ctrl {
  height: 41.42px;
  font-size: 22.51px;
  padding: 6.01px 34.06px;
  padding-right: 54.77px;
  position: relative;
  background-color: #fff;
  align-items: center;
  color: $blul;
  align-content: center;
  cursor: pointer;

  &::after {
    width: 20.44px;
    height: 11.49px;
    right: 26.93px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0);
    background-image: url(../img/icons/arrow.svg);
    transition: .3s;
  }

  &.opened {
    &::after {
      transform: translateY(-50%) rotate(-90deg);
    }
  }
}

.faq__answer {
  font-size: 16.04px;
  color: #fff;
  font-weight: 400;
  background-color: #006ecf;
  height: 0;
  overflow: hidden;
  transition: .3s;

  .faq__ansinner {
    padding: 11.99px 34.06px;
  }
}



@media screen and (max-width: 1300px) {
  .section__faq {
    padding-top: 4.51vw;
    padding-bottom: 6.43vw;
  }
  .faq__title {
    font-size: 6.02vw;
  }
  .faq__cont {
    margin-top: 2.71vw;
    max-width: 67.28vw;
  }
  .faq__item {
    margin-bottom: 0.80vw;
    border-radius: 0.50vw;
  }
  .faq__ctrl {
    height: 4.16vw;
    font-size: 2.26vw;
    padding: 0.60vw 3.41vw;
    padding-right: 5.51vw;
    &::after {
      width: 2.05vw;
      height: 1.15vw;
      right: 2.71vw;
    }
  }
  .faq__answer {
    font-size: 1.60vw;
    .faq__ansinner {
      padding: 1.20vw 3.41vw;
    }
  }
}


@media screen and (max-width: 778px) {
  .section__faq {
    padding-top: 9vw;
    padding-bottom: 12vw;
  }
  .faq__title {
    font-size: 9.65vw;
  }
  .faq__cont {
    margin: 0 auto;
    margin-top: 7.71vw;
    max-width: 90.83vw;
  }
  .faq__item {
    margin-bottom: 2.32vw;
    border-radius: 1.29vw;
  }
  .faq__ctrl {
    min-height: 10.65vw;
    height: auto;
    font-size: 4.88vw;
    padding: 1.54vw 3.34vw;
    padding-right: 9.77vw;
    &::after {
      width: 5.27vw;
      height: 2.96vw;
      right: 2.83vw;
    }
  }
  .faq__answer {
    font-size: 4.37vw;
    .faq__ansinner {
      padding: 2.06vw 3.34vw;
    }
  }
}