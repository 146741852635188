html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}
body {
  overflow-x: hidden;
  position: relative;
}

* {
  box-sizing: border-box; }

a {
  text-decoration: none !important;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  font-weight: normal; }

li {
  margin: 0;
  padding: 0;
  list-style: none; }

p {
  margin: 0;
}

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
  border: none;
}

select {
  outline: none;
  border: none;
}

.wrapper {
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flexCentr {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.cover {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contain {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hr {
  width: 100%;
}



//-------------------------//
$blul: #004a99;
$blud: #003e80;
$red: #d83644;
$green: #00805d;
$yellow: #ffe400;
$ffrc: 'Roboto Condensed', sans-serif;;



body {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #000;
}

.container {
  padding: 0 31.93px;
  margin: 0 auto;
}


@media screen and (max-width: 1300px) {
  .container {
    padding: 0 3.21vw;
  }
}


@media screen and (max-width: 778px) {
  .container {
    padding: 0 2.06vw;
  }
  .notOnMob {
    display: none;
  }
}