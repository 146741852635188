.section__reverenc {
  padding-top: 69.48px;
  padding-bottom: 81.40px;
}

.referenc__title {
  width: 457.93px;
  height: 73.52px;
  border-radius: 12.03px;
  font-size: 36.08px;
  color: #fff;
  background-color: $blul;
  margin: 0 auto;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.referenc__logos {
  margin: 0 auto;
  margin-top: 42.13px;
  margin-bottom: -27.10px;
  max-width: 1000px;
  justify-content: center;

  li {
    margin: 0 27.10px;
    margin-bottom: 27.10px;

    img {
      height: 51.81px;
      width: auto;
      display: block;
    }
  }
}

.referenc__colls {
  margin-top: 40.96px;
  justify-content: center;
}

.referenc__coll {
  width: 404.65px;
  margin-right: 54.23px;

  &:last-child {
    margin: 0;
  }

  ol {
    li {
      font-size: 14.45px;
      margin-bottom: 14.45px;
      font-weight: 500;
      color: #b0b0b0;
      list-style: decimal;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        color: #7f7f7f;
      }
    }
  }
}


@media screen and (max-width: 1100px) {
  .section__reverenc {
    padding-top: 5.81vw;
    padding-bottom: 6.77vw;
  }
  .referenc__title {
    width: 38.11vw;
    height: 6.12vw;
    border-radius: 1.00vw;
    font-size: 3.01vw;
  }
  .referenc__logos {
    margin-top: 3.51vw;
    margin-bottom: -2.26vw;
    max-width: 70.1vw;
    li {
      margin: 0 2.26vw;
      margin-bottom: 2.26vw;
      img {
        height: 4.32vw;
      }
    }
  }
  .referenc__colls {
    margin-top: 3.41vw;
  }
  .referenc__coll {
    width: 33.73vw;
    margin-right: 4.51vw;
    ol {
      li {
        font-size: 1.20vw;
        margin-bottom: 1.20vw;
      }
    }
  }
}



@media screen and (max-width: 778px) {
  .section__reverenc {
    padding-top: 10.02vw;
    padding-bottom: 10.29vw;
  }
  .referenc__title {
    width: 82.32vw;
    height: 15.67vw;
    border-radius: 2.57vw;
    font-size: 7.71vw;
  }
  .referenc__logos {
    margin: 0 auto;
    margin-top: 8.23vw;
    margin-bottom: -4.63vw;
    max-width: 100%;
    justify-content: center;
    li {
      margin: 3.09vw;
      margin-bottom: 4.63vw;
      order: 1;
      &:nth-child(2) {
        order: 0;
      }
      &:last-child {
        display: none;
      }
      img {
        height: 11.06vw;
      }
    }
  }
  .referenc__colls {
    margin: 0 auto;
    margin-top: 8.23vw;
    width: 87.55vw;
  }
  .referenc__coll {
    margin-bottom: 3.09vw;
    width: 100%;
    margin-right: 0;
    ol {
      margin-bottom: 0;
      li {
        font-size: 3.09vw;
        margin-bottom: 3.09vw;
      }
    }
  }
}