.section__second {
  padding-top: 26.58px;
  padding-bottom: 18.38px;
  background-color: $blud;
  position: relative;

  &::after {
    width: 51.99px;
    height: 34.84px;
    content: '';
    position: absolute;
    bottom: 17.37px;
    left: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    background-image: url(../img/decor/arrow.svg);
    transform: translateY(100%) translateX(-50%);
  }
}

.second__title {
  font-size: 43.19px;
  text-align: center;
  font-weight: 700;
  color: $yellow;
  span {
    font-size: .5em;
    top: -.4em;
    position: relative;
  }
}

.second__timer {
  text-align: center;
  margin-top: 5.08px;
  font-size: 64.98px;
  color: #fff;
  font-weight: 700;
}


@media screen and (max-width: 1100px) {
  .section__second {
    padding-top: 2.41vw;
    padding-bottom: 1.71vw;
    &::after {
      width: 4.82vw;
      height: 3.21vw;
      bottom: 1.5vw;
    }
  }
  .second__title {
    font-size: 4.01vw;
  }
  .second__timer {
    margin-top: 0.5vw;
    font-size: 6.02vw;
  }
}


@media screen and (max-width: 778px) {
  .section__second {
    padding-top: 6.94vw;
    padding-bottom: 4.12vw;
    &::after {
      width: 12.34vw;
      height: 8.22vw;
      bottom: 4.12vw;
    }
  }
  .second__title {
    font-size: 7.72vw;
    br {
      display: none;
    }
  }
  .second__timer {
    margin-top: 2.06vw;
    font-size: 15.41vw;
  }
}

