.section__money {
  padding-top: 74.1821px;
  padding-bottom: 64.7701px;
}

.monay__container {
  width: 817.7199px;
  border: 7.0045px solid $blul;
  border-radius: 12.517px;
  padding: 37.911px 49.904px;
  background-color: #f2f8ff;
  margin: 0 auto;
  position: relative;

  &::after {
    left: 6px;
    top: 6px;
    right: 6px;
    bottom: 6px;
    border-radius: 12.517px;
    border: 6px dashed #ffc60d;
    content: '';
    position: absolute;
  }

  &::before {
    height: 14.976px;
    width: 278.8523px;
    background-color: #f2f8ff;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: absolute;
  }
}

.money__line {
  left: 12.4673px;
  right: 12.4673px;
  height: 7.99476px;
  top: -7.59392px;
  position: absolute;
  background-color: #fff;

  &::before, &::after {
    width: 273.8897px;
    height: 7.00445px;
    top: 0.5011px;
    bottom: 0.5011px;
    content: '';
    position: absolute;
    background-color: $blul;
  }

  &::before {
    left: 0;
    border-radius: 0 7.0045px 7.0045px 0;
  }

  &::after {
    right: 0;
    border-radius: 7.0045px 0 0 7.0045px;
  }
}

.money__top {
  font-size: 116.8961px;
  font-weight: 700;
  color: $blul;
  line-height: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  img {
    width: 209.8835px;
    height: 209.8835px;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    position: absolute;
  }

  span {
    margin-right: 326.9971px;
    width: 100%;

    &:first-child {
      text-align: right;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.money__second {
  font-size: 54.9808px;
  margin-top: 10.9925px;
  font-weight: 700;
  text-align: center;
  color: $blul;
  letter-spacing: -0.01251em;
}

.money__list {
  margin-top: 31.028px;
  position: relative;
  z-index: 5;

  li {
    margin-bottom: 25.965px;
    font-size: 18.26px;
    letter-spacing: -0.0174867em;
    display: flex;
    align-items: center;
    align-content: center;
    font-weight: 400;

    span {
      width: 55.491px;
      height: 55.491px;
      min-width: 55.491px;
      font-size: 35.95px;
      margin-right: 15.965px;
      font-weight: 700;
      color: #fff;
      border-radius: 50%;
      background-color: $blul;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }

    &:last-child {
      margin: 0;
    }
  }
}

/////////////
.monay__bottom {
  margin-top: 27.9254px;
  justify-content: center;
}

.money__bottomlist {
  li {
    margin-right: 20.0898px;

    &:last-child {
      margin: 0;
    }

    img {
      width: 159.6543px;
      height: auto;
      display: block;
    }
  }
}


@media screen and (max-width: 1100px) {
  .section__money {
    padding-top: 7.46vw;
    padding-bottom: 6.51vw;
  }
  .monay__container {
    width: 82.09vw;
    border: 0.7vw solid $blul;
    border-radius: 1.25vw;
    padding: 3.81vw 5.01vw;
    &::after {
      left: 0.60vw;
      top: 0.60vw;
      right: 0.60vw;
      bottom: 0.60vw;
      border-radius: 1.25vw;
      border: 0.60vw dashed $yellow;
    }
    &::before {
      height: 1.50vw;
      width: 27.89vw;
    }
  }
  .money__line {
    left: 1.25vw;
    right: 1.25vw;
    height: 0.8vw;
    top: -0.75vw;
    &::before, &::after {
      width: 27.46vw;
      height: 0.7vw;
    }
    &::before {
      border-radius: 0 0.7vw 0.7vw 0;
    }
    &::after {
      border-radius: 0.7vw 0 0 0.7vw;
    }
  }
  .money__top {
    font-size: 11.73vw;
    img {
      width: 21.06vw;
      height: 21.06vw;
      bottom: -0.6vw;
    }
    span {
      margin-right: 27.16vw;
    }
  }
  .money__second {
    font-size: 5.51vw;
    margin-top: 1.1vw;
  }
  .money__list {
    margin-top: 3.11vw;
    li {
      margin-bottom: 2.61vw;
      font-size: 1.9vw;
      span {
        width: 5.56vw;
        height: 5.56vw;
        min-width: 5.56vw;
        font-size: 3.61vw;
        margin-right: 1.6vw;
      }
    }
  }
  /////////////
  .monay__bottom {
    margin-top: 2.81vw;
  }
  .money__bottomlist {
    li {
      margin-right: 2.01vw;
      img {
        width: 16.02vw;
      }
    }
  }
}


@media screen and (max-width: 778px) {
  .section__money {
    padding-top: 12.85vw;
    padding-bottom: 8.47vw;
  }
  .monay__container {
    width: 93.9vw;
    border: 1.54vw solid $blul;
    border-radius: 3.22vw;
    padding: 0 4.11vw;
    padding-bottom: 6.68vw;
    padding-top: 4.37vw;
    &::after {
      display: none;
    }
    &::before {
      height: 1.8vw;
      width: 43.01vw;
      top: -1.67vw;
      background-color: #fff;
    }
  }
  .money__line {
    left: 3.21vw;
    right: 3.21vw;
    height: 1.54vw;
    top: -1.54vw;
    z-index: 5;
    &::before, &::after {
      width: 25.97vw;
      height: 1.54vw;
      top: 0;
      bottom: 0;
    }
    &::before {
      left: 0;
      border-radius: 0 3.22vw 3.22vw 0;
    }
    &::after {
      right: 0;
      border-radius: 3.22vw 0 0 3.22vw;
    }
  }
  .money__top {
    font-size: 13.39vw;
    position: relative;
    z-index: 10;
    img {
      width: 29.79vw;
      height: 29.79vw;
      bottom: -1.03vw;
    }
    span {
      margin-right: 34.91vw;
    }
  }
  .money__second {
    font-size: 6.81vw;
    margin-top: 2.06vw;
  }
  .money__list {
    margin-top: 6.43vw;
    li {
      margin-bottom: 6.43vw;
      font-size: 4.64vw;
      letter-spacing: -0.025em;
      display: block;
      text-align: center;
      span {
        display: none;
      }
      b:first-child {
        font-size: 5.53vw;
        width: 100%;
        display: block;
        text-align: center;
      }
    }
  }
  /////////////
  .monay__bottom {
    margin-top: 6.94vw;
  }
  .money__bottomlist {
    li {
      margin-right: 2.31vw;
      img {
        width: 20.93vw;
      }
    }
  }
}