.section__reviews {
  padding-top: 43.9281px;
  padding-bottom: 64.9997px;
}

.reviews__title {
  font-size: 70.07051px;
  text-align: center;
  font-weight: 700;
  color: $blul;

  b {
    font-size: .249em;
    top: -1.105em;
    position: relative;
  }

  span {
    padding: 4.98971px 24.56px;
    border-radius: 15.00107px;
    margin-top: 6.50273px;
    display: inline-block;
    background-color: $blul;
    color: #fff;
  }
}

.photos__cont {
  margin-top: 38.5045px;
  justify-content: center;

  img {
    width: 638.50108px;
    height: auto;
  }

  &.photos__bottom {
    margin-top: 39.99756px;
  }

  .mob {
    display: none;
  }
}

.reviews__list {
  margin: 0 auto;
  margin-top: 45.89437px;
  width: 727.98822px;
}

.review__item {
  padding-bottom: 39.70868px;
  margin-bottom: 37.32885px;
  padding-left: 49.9841px;
  padding-top: 32.42785px;
  position: relative;

  &:last-child {
    margin: 0;
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }

  &::after {
    height: 4.98971px;
    border-radius: 4.98971px;
    background-color: #e5e5e5;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
  }
}

.review__ava {
  width: 65.8341px;
  min-width: 65.8341px;
  height: 66.55077px;
  border-radius: 9.99542px;
  border: 3.59073px solid $blul;
  position: relative;
  overflow: hidden;
}

.reviews__main {
  width: calc(100% - 65.8341px);
  padding-left: 10.98438px;
}

.review__name {
  font-size: 32.41173px;
  color: $blul;
  letter-spacing: -.0087em;
  line-height: 1;
}

.review__middle {
  margin-top: 10.98438px;
  align-items: center;
  align-content: center;
}

.review__stars {
  span {
    margin-right: 2.09311px;

    &:last-child {
      margin: 0;
    }

    img {
      width: 23.52808px;
      display: block;
      height: auto;
    }
  }
}

.stars__text {
  width: 56.7517px;
  height: 22.9904px;
  border-radius: 6.021px;
  font-size: 15.0061px;
  margin-left: 9.1241px;
  font-weight: 700;
  color: #fff;
  background-color: #75b843;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.review__verify {
  margin-left: 7.9985px;
  font-size: 16.3387px;
  color: $blul;

  * {
    vertical-align: center;
  }

  span {
    width: 17.8961px;
    height: 17.8961px;
    margin-right: 3.0017px;
    transform: translateY(16%);
    display: inline-block;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.review__main {
  margin-top: 12.429px;
}

.review__text {
  font-size: 19.9945px;
  margin-top: 12.429px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  position: relative;

  .review__name {
    display: none;
  }

  &::before {
    width: 13.359px;
    height: 11.8978px;
    left: -6.6386px;
    top: 0;
    transform: translateX(-100%);
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    background-image: url(../img/icons/lap.svg);
  }
}

.review__bottom {
  font-size: 19.9945px;
  margin-top: 13.611px;
  font-weight: 400;
  color: $blul;
}


@media screen and (max-width: 1100px) {
  .section__reviews {
    padding-top: 4.41vw;
    padding-bottom: 6.51vw;
  }
  .reviews__title {
    font-size: 7.02vw;
    span {
      padding: 0.5vw 2.51vw;
      border-radius: 1.51vw;
      margin-top: 0.65vw;
    }
  }
  .photos__cont {
    margin-top: 3.87vw;
    img {
      width: 63.89vw;
    }
    &.photos__bottom {
      margin-top: 4.01vw;
    }
  }
  .reviews__list {
    margin-top: 4.61vw;
    width: 73.22vw;
  }
  .review__item {
    padding-bottom: 4.01vw;
    margin-bottom: 3.75vw;
    padding-left: 5.01vw;
    padding-top: 3.26vw;
    &::after {
      height: 0.5vw;
      border-radius: 0.5vw;
    }
  }
  .review__ava {
    width: 6.62vw;
    min-width: 6.62vw;
    height: 6.71vw;
    border-radius: 1.00vw;
    border: 0.35vw solid $blul;
  }
  .reviews__main {
    width: calc(100% - 6.62vw);
    padding-left: 1.10vw;
  }
  .review__name {
    font-size: 3.26vw;
  }
  .review__middle {
    margin-top: 1.10vw;
  }
  .review__stars {
    span {
      margin-right: 0.20vw;
      img {
        width: 2.36vw;
      }
    }
  }
  .stars__text {
    width: 5.66vw;
    height: 2.31vw;
    border-radius: 0.60vw;
    font-size: 1.50vw;
    margin-left: 0.90vw;
  }
  .review__verify {
    margin-left: 0.80vw;
    font-size: 1.65vw;
    span {
      width: 1.81vw;
      height: 1.81vw;
      margin-right: 0.30vw;
    }
  }
  .review__main {
    margin-top: 1.2vw;
  }
  .review__text {
    font-size: 2.01vw;
    margin-top: 1.20vw;
    &::before {
      width: 2.1vw;
      height: 1.8vw;
      left: -1vw;
    }
  }
  .review__bottom {
    font-size: 2.01vw;
    margin-top: 1.30vw;
  }
}


@media screen and (max-width: 778px) {
  .section__reviews {
    padding-top: 8.47vw;
    padding-bottom: 8.99vw;
  }
  .reviews__title {
    font-size: 8.99vw;
    span {
      padding: 1.03vw;
      border-radius: 1.93vw;
      margin-top: 1.03vw;
    }
  }
  .photos__cont {
    margin-top: 8.47vw;
    justify-content: center;
    img {
      width: 82.55vw;
      display: none;
    }
    .mob {
      display: block;
    }
    &.photos__bottom {
      margin-top: 10.28vw;
    }
  }
  .reviews__list {
    margin: 0 auto;
    margin-top: 1.29vw;
    width: 82.55vw;
  }
  .review__item {
    padding-bottom: 6.63vw;
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 7.71vw;
    align-items: center;
    align-content: center;
    &::after {
      height: 1.29vw;
      border-radius: 1.29vw;
    }
  }
  .review__ava {
    width: 17.22vw;
    min-width: 17.22vw;
    height: 17.22vw;
    border-radius: 2.57vw;
    border: 1.03vw solid $blul;
  }
  .reviews__main {
    width: calc(100% - 17.22vw);
    padding-left: 2.57vw;
    .review__name {
      display: none;
    }
  }
  .review__name {
    font-size: 7.71vw;
    margin-bottom: 4.11vw;
    padding-left: 7.45vw;
    font-family: $ffrc;
    font-style: normal;
  }
  .review__middle {
    margin-top: 0;
  }
  .review__stars {
    span {
      margin-right: 0.77vw;
      img {
        width: 6.05vw;
      }
    }
  }
  .stars__text {
    width: 14.65vw;
    height: 5.91vw;
    border-radius: 1.55vw;
    font-size: 3.86vw;
    margin-left: 2.32vw;
  }
  .review__verify {
    width: 100%;
    margin-left: 0;
    font-size: 4.24vw;
    margin-top: 2.32vw;
    span {
      width: 4.63vw;
      height: 4.63vw;
      margin-right: 0.51vw;
    }
  }
  .review__main {
    margin-top: 3.60vw;
  }
  .review__text {
    font-size: 4.63vw;
    .review__name {
      display: block;
    }
    p {
      text-align: justify;
    }
    &::before {
      width: 5.4vw;
      height: 4.63vw;
      left: 0;
      top: 1.29vw;
      transform: none;
    }
  }
  .review__bottom {
    font-size: 5.14vw;
    margin-top: 2.57vw;
  }
}
