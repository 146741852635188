.section__products {
  padding-top: 23.773px;
  padding-bottom: 56.727px;
  background-color: #eeeeee;
}

.prdoucts__cont {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.product__item {
  width: 313.833px;
  margin: 0 6.498px;
  padding-bottom: 18.428px;
  border: 5.396px solid #a2a2a2;
  border-radius: 21.7114px;
  background-color: #fff;

  &.main {
    width: 345.517px;
    border-color: $blul;
    background: linear-gradient(184deg, rgba(250,250,179,1) 0%, rgba(255,255,255,1) 100%);

    .product__top {
      position: relative;
      left: -5.396px;
      top: -5.396px;
      right: -5.396px;
      border-radius: 20.75px 20.75px 0 0;
      width: 345.517px;
      height: 55.3837px;
      background-color: $red;
    }

    .product__name, .product__price, .price__real {
      color: $blul;
    }

    .prodict__img {
      height: 175.5238px;

      .save {
        right: 8.20712px;
      }
    }

    .product__button {
      width: 306.8px;
      height: 73.7544px;
      font-size: 44.3217px;
      padding-left: 24.41105px;
      padding-right: 9.28895px;
      border-radius: 72.467px;
      -webkit-box-shadow: inset 0px -6.64px 0px -1.99653px rgba(0,0,0,0.08);
      -moz-box-shadow: inset 0px -6.64px 0px -1.99653px rgba(0,0,0,0.08);
      box-shadow: inset 0px -6.64px 0px -1.99653px rgba(0,0,0,0.08);

      .pr__icon {
        width: 56.7122px;
        height: 56.7122px;
        &::before {
          -webkit-background-size: 36.52px;
          background-size: 36.52px;
          right: 4.15px;
        }
      }
    }

    .price__real {
      span {
        text-decoration-color: #ff0003;
      }
    }
  }
}

.product__top {
  height: 49.8315px;
  font-size: 22.1954px;
  color: #fff;
  font-weight: 700;
  background-color: #a2a2a2;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4.15px 4.15px 0 0;
}

.product__main {
  padding-top: 15.11625px;
  text-align: center;
}

.product__name {
  font-size: 38.9537px;
  color: #17589e;
  font-weight: 700;
}

.product__days {
  font-size: 24.45px;
  font-weight: 700;
}

.prodict__img {
  margin-top: 13.45px;
  height: calc(201.202px * 0.83);
  position: relative;
  display: inline-flex;

  img {
    display: inline-block;
    height: 100%;
  }

  .mob {
    display: none;
  }
}

.save {
  width: 75.90px;
  height: 80.75px;
  font-size: 24.10px;
  top: -7.41px;
  right: -22.65px;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-family: $ffrc;
  position: absolute;
  line-height: 1;
  letter-spacing: -0.035em;
  transform: rotate(-10deg);
  background-image: url(../img/icons/save.png);

  * {
    vertical-align: top;
  }
  span {
    font-size: 17.97px;
  }
}
.width {
  width: 100%;
}

.product__price {
  font-size: 74.76px;
  margin-left: 26.28px;
  color: #17589e;
  font-weight: 700;
  position: relative;
  display: inline-flex;
  align-items: center;
  align-content: center;

  span {
    font-size: 13.43px;
    font-weight: 500;
    align-content: center;
  }

  &::before {
    content: '$';
    font-size: 31.64px;
    top: 5.96px;
    left: -15.90px;
    position: absolute;
  }
}

.product__shiping {
  font-size: 20.57px;
  margin-top: 5.09px;
  margin-bottom: 19.69px;
  color: #17589e;
  font-style: italic;
  font-weight: 500;
}

.product__list {
  margin-bottom: 13.44px;
  width: 246.26px;
  display: inline-block;

  li {
    min-height: 31.16px;
    padding-left: 47.05px;
    padding-right: 4.99px;
    border-radius: 7.72px;
    margin-bottom: 4.08px;
    font-size: 20.57px;
    color: #fff;
    font-weight: 700;
    font-style: italic;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;

    &:last-child {
      margin: 0;
    }

    &::before {
      width: 21.07px;
      height: 21.07px;
      left: 15.01px;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      background-image: url(../img/icons/check.svg);
    }
  }
}

.product__button {
  width: 259.69px;
  height: 61.784px;
  border-radius: 60.915px;
  padding-left: 24.955px;
  padding-right: 6.278px;
  font-size: 37.4332px;
  letter-spacing: -0.035em;
  background-color: $yellow;
  display: inline-flex;
  color: #000;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  font-family: $ffrc;
  font-weight: 700;
  background: linear-gradient(360deg, rgba(255,179,1,1) 0%, rgba(255,222,0,1) 100%);
  position: relative;

  .pr__icon {
    width: 50.99px;
    height: 50.99px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: $yellow;
    background: linear-gradient(180deg, rgba(255,179,1,1) 0%, rgba(255,222,0,1) 100%);
    position: relative;

    &::before {
      -webkit-background-size: 31.93px;
      background-size: 31.93px;
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
      position: absolute;
      left: 0;
      right: 4.22px;
      bottom: 0;
      top: 0;
      background-image: url(../img/icons/cart.png);
    }
  }
}

.product__cards {
  margin-top: 4.98px;
  display: flex;
  justify-content: center;

  img {
    width: 46.48px;
    margin: 0 1.51px;
    height: auto;
  }
}

.price__real {
  font-size: 17.91px;
  margin-top: 15.0px;
  font-weight: 700;
  color: #17589e;

  span {
    text-decoration: line-through;
  }
}

.products__bottom {
  margin-top: 28.95px;
  text-align: center;
}

.prodbot__title {
  font-size: 22.5px;
  font-weight: 700;
}

.product__stars {
  margin-top: 15.0px;
  display: inline-flex;
  align-items: center;
  align-content: center;
}

.product__starscont {
  margin-right: 12.51px;
  display: flex;

  span {
    margin-right: 3.55px;

    &:last-child {
      margin: 0;
    }

    img {
      width: 38.05px;
      height: auto;
      display: block;
    }
  }
}

.product__rate {
  font-size: 26.48px;
  font-weight: 900;
  color: $blul;
  display: flex;
  align-items: center;
  align-content: center;

  img {
    width: 28.9px;
    margin-right: 10.0px;
    height: auto;
  }
}

.prodbot__text {
  font-size: 22.5px;
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}


@media screen and (max-width: 1100px) {
  .section__products {
    padding-top: 2.21vw;
    padding-bottom: 5.26vw;
  }
  .product__item {
    width: 29.16vw;
    margin: 0 0.6vw;
    padding-bottom: 1.71vw;
    border: 0.5vw solid #a2a2a2;
    border-radius: 2vw;
    &.main {
      width: 32.21vw;
      .product__top {
        left: -0.5vw;
        top: -0.5vw;
        right: -0.5vw;
        border-radius: 1.9vw 1.9vw 0 0;
        width: 32.16vw;
        height: 5.11vw;
      }
      .prodict__img {
        height: 16.23vw;
        .save {
          right: 0.75vw;
        }
      }
      .product__button {
        width: 28.39vw;
        height: 6.77vw;
        font-size: 4.11vw;
        padding-left: 2.26vw;
        padding-right: 0.85vw;
        border-radius: 6.77vw;
        -webkit-box-shadow: inset 0px -0.6vw 0px -0.2vw rgba(0,0,0,0.08);
        -moz-box-shadow: inset 0px -0.6vw 0px -0.2vw rgba(0,0,0,0.08);
        box-shadow: inset 0px -0.6vw 0px -0.2vw rgba(0,0,0,0.08);
        .pr__icon {
          width: 5.26vw;
          height: 5.26vw;
          &::before {
            -webkit-background-size: 3.38vw;
            background-size: 3.38vw;
            right: 0.38vw;
          }
        }
      }
    }
  }
  .product__top {
    height: 4.62vw;
    font-size: 2.05vw;
  }
  .product__main {
    padding-top: 1.4vw;
  }
  .product__name {
    font-size: 3.61vw;
  }
  .product__days {
    font-size: 2.25vw;
  }
  .prodict__img {
    margin-top: 1.25vw;
    height: 15.5vw;
  }
  .save {
    width: 7.63vw;
    height: 8.12vw;
    font-size: 2.41vw;
    top: -0.75vw;
    right: -2.01vw;
    span {
      font-size: 1.81vw;
    }
  }
  .product__price {
    font-size: 7.51vw;
    margin-left: 2.76vw;
    span {
      font-size: 1.35vw;
    }
    &::before {
      font-size: 3.61vw;
      top: 0.75vw;
      left: -1.81vw;
    }
  }
  .product__shiping {
    font-size: 2.05vw;
    margin-top: 0.6vw;
    margin-bottom: 1.81vw;
  }
  .product__list {
    margin-bottom: 1.4vw;
    width: 25.46vw;
    li {
      min-height: 3.11vw;
      padding-left: 4.71vw;
      padding-right: 0.5vw;
      border-radius: 1.2vw;
      margin-bottom: 0.8vw;
      font-size: 2.05vw;
      &::before {
        width: 2.11vw;
        height: 2.11vw;
        left: 1.51vw;
      }
    }
  }
  .product__button {
    width: 25.92vw;
    height: 6.16vw;
    border-radius: 2.61vw;
    padding-left: 2.51vw;
    padding-right: 0.6vw;
    font-size: 3.76vw;
    .pr__icon {
      width: 5.12vw;
      height: 5.12vw;
      &::before {
        -webkit-background-size: 3.05vw;
        background-size: 3.05vw;
        right: 0.38vw;
      }
    }
  }
  .product__cards {
    margin-top: 0.5vw;
    img {
      width: 4.66vw;
      margin: 0 0.15vw;
    }
  }
  .price__real {
    font-size: 1.81vw;
    margin-top: 1.51vw;
  }
  .products__bottom {
    margin-top: 2.91vw;
  }
  .prodbot__title {
    font-size: 2.25vw;
  }
  .product__stars {
    margin-top: 1.51vw;
  }
  .product__starscont {
    margin-right: 1.25vw;
    span {
      margin-right: 0.35vw;
      img {
        width: 3.81vw;
      }
    }
  }
  .product__rate {
    font-size: 2.66vw;
    img {
      width: 2.91vw;
      margin-right: 1vw;
    }
  }
  .prodbot__text {
    font-size: 2.26vw;
    margin-top: 1.5vw;
  }
}



@media screen and (max-width: 778px) {
  .section__products {
    padding-top: 7.71vw;
    padding-bottom: 8.37vw;
  }
  .prdoucts__cont {
    padding: 1.16vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .product__item {
    width: 100% !important;
    margin: 0;
    padding-bottom: 3.86vw;
    margin-bottom: 4.5vw !important;
    border: 1.29vw solid #a9a9a9;
    border-radius: 5.13vw;
    order: 2;
    &:last-child {
      order: 1;
    }
    &.main {
      order: 0;
      border-color: $blul;
      .product__top {
        left: -1.29vw;
        top: -1.29vw;
        right: -1.29vw;
        border-radius: 5vw 5vw 0 0;
        width: calc(100% + 2.58vw);
        height: 11.56vw;
        display: flex;
      }
      .prodict__img {
        height: 33.43vw;
        .save {
          right: 3.09vw;
          bottom: 2.06vw;
          top: auto;
        }
      }
      .product__button {
        width: 43.4vw;
        height: 10.94vw;
        border-radius: 10.94vw;
        padding-left: 3.86vw;
        padding-right: 0.77vw;
        font-size: 6.43vw;
        -webkit-box-shadow: inset 0px -1vw 0px -0.2vw rgba(0,0,0,0.08);
        -moz-box-shadow: inset 0px -1vw 0px -0.2vw rgba(0,0,0,0.08);
        box-shadow: inset 0px -1vw 0px -0.2vw rgba(0,0,0,0.08);
        .pr__icon {
          width: 9vw;
          height: 9vw;
          &::before {
            -webkit-background-size: 5.8vw;
            background-size: 5.8vw;
            right: 0.64vw;
          }
        }
      }
      .price__real {
        color: $blul;
      }
    }
  }
  .product__top {
    font-size: 6.05vw;
    padding-bottom: 0;
    display: none;
  }
  .product__main {
    padding-top: 3.09vw;
    padding-left: 3.86vw;
    padding-right: 2.58vw;
    text-align: center;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    position: relative;
    align-items: center;
    align-content: center;
  }
  .product__mt {
    padding-bottom: 8.25vw;
  }
  .product__name {
    font-size: 7.71vw;
    width: 41.7vw;
  }
  .product__days {
    font-size: 4.63vw;
    width: 41.7vw;
  }
  .width {
    display: none;
  }
  .prodict__img {
    margin-top: 2.05vw;
    height: 31.06vw;
    width: 41.63vw;
    display: block;
    text-align: center;
    img {
      display: none;
      height: 100%;
    }
    .mob {
      display: inline-block;
    }
  }
  .save {
    width: 14.38vw;
    height: 14.38vw;
    font-size: 4.12vw;
    top: auto;
    bottom: 2.05vw;
    right: 3.59vw;
    span {
      font-size: 4.12vw;
    }
  }
  .product__price {
    font-size: 17.99vw;
    margin-left: 4.88vw;
    letter-spacing: -0.05em;
    span {
      font-size: 3.34vw;
      margin-left: 1.28vw;
      letter-spacing: 0em;
    }
    &::before {
      font-size: 8.98vw;
      top: 1.54vw;
      left: -4.12vw;
    }
  }
  .product__shiping {
    font-size: 5.14vw;
    margin-top: 0;
    margin-bottom: 2.83vw;
  }
  .product__list {
    margin-bottom: 3.34vw;
    width: 43.39vw;
    li {
      min-height: 5.4vw;
      padding-left: 7.71vw;
      padding-right: 1.28vw;
      border-radius: 1.92vw;
      margin-bottom: 0.88vw;
      font-size: 3.34vw;
      &::before {
        width: 3.73vw;
        height: 3.73vw;
        left: 2.31vw;
      }
    }
  }
  .product__button {
    width: 43.39vw;
    height: 10.92vw;
    border-radius: 10.92vw;
    padding-left: 3.86vw;
    padding-right: 0.98vw;
    font-size: 6.43vw;
    .pr__icon {
      width: 8.99vw;
      height: 8.99vw;
      &::before {
        -webkit-background-size: 5.8vw;
        background-size: 5.8vw;
        right: 0.64vw;
      }
    }
  }
  .product__cards {
    margin-top: 1.54vw;
    img {
      width: 7.96vw;
      margin: 0 0.64vw;
    }
  }
  .price__real {
    font-size: 4.51vw;
    margin-top: 0;
    position: absolute;
    left: 3.86vw;
    bottom: 0;
    width: 41.63vw;
    color: #457ab1;
  }
  .products__bottom {
    margin: 0;
    padding-top: 3.86vw;
  }
  .prodbot__title {
    font-size: 5.27vw;
  }
  .product__stars {
    margin-top: 3.08vw;
  }
  .product__starscont {
    margin-right: 3.34vw;
    span {
      margin-right: 1.03vw;
      img {
        width: 8.98vw;
      }
    }
  }
  .product__rate {
    font-size: 6.3vw;
    img {
      width: 6.94vw;
      margin-right: 2.31vw;
      height: auto;
    }
  }
  .prodbot__text {
    font-size: 5.28vw;
    margin-top: 3.6vw;
  }
}